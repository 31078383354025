import React from 'react';

import { Box, Button, Grid } from '@core';
import { ImageCore, LinkWrapper, Text } from '@components';

const DeviceCard = ({ image, title, button }) => (
  <Grid.Container px={12} py={{ lg: 32 }} width={144}>
    <Grid.Row justifyContent="center">
      <Grid.Col display="flex" justifyContent="center" alignItems="center" height={100}>
        <ImageCore src={image} alt={title} minWidth={48} />
      </Grid.Col>
      <Grid.Col mt={8}>
        <Text.Body3Strong textAlign="center">{title}</Text.Body3Strong>
      </Grid.Col>
      {button && (
        <Box mt={16} width={120}>
          <LinkWrapper link={button.link}>
            <Button type="button" size="small" {...button.buttonProps} fullWidth>
              {button.title}
            </Button>
          </LinkWrapper>
        </Box>
      )}
    </Grid.Row>
  </Grid.Container>
);

export default DeviceCard;
